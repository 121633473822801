






















import { Vue, Component } from 'vue-property-decorator';
import Service from '@/lib/jsskd/service';

@Component({
  name: ''
})
export default class extends Vue {
  get options() {
    return [
      {
        icon: '//means-static.luckyop.com/images/20210929/1fgo2fns4ocongipnfhg.png',
        label: '常见问题',
        url: 'mine/issue'
      },
      {
        icon: '//means-static.luckyop.com/images/20210929/1fgo2g6bk940pr3tc6fg.png',
        label: '收货地址',
        url: 'address/list'
      },
      // {
      //   icon: '//means-static.luckyop.com/images/20210929/1fgo2ggu80k9oe7i69ug.png',
      //   label: '联系客服',
      //   url: '',
      //   methods: 'openOnlineService'
      // },
      {
        icon: '//means-static.luckyop.com/images/20211029/1fj5mdnjaq0hdm08fojo.png',
        label: '优惠券兑换',
        url: 'mine/card'
      }
    ];
  }

  openOnlineService() {
    Service.openOnlineService();
  }

  handleClickGridItem(event?: string) {
    if (event === 'openOnlineService') {
      this.openOnlineService();
    }
  }
}
