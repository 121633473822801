import request, { baseQueryByPage } from '@/utils/request';
import { IRequestResult } from '../type/tool';
import { ICouponInfo } from '@/api/type/base';
import { MerchantModule } from '@/store/modules/Merchant';

// 用户信息
export interface IUserInfo {
  /** 用户ID */
  userId: string;
  /** 用户账号 */
  userAccount: string;
  /** 用户姓名 */
  userName: string;
  /** 绑定号码 */
  userTel: string;
  /** 昵称 */
  userNickName: string;
  /** 所属商户appId */
  merchantAppId: string;
  /** 用户头像 */
  userHeadImg: string;
  /** 集团名称 */
  merchantName: string;
  /** 公司名称 */
  merchantSubName: string;
}
export type loginRes = {
  jwt: string;
  userInfo: IUserInfo;
};
// 发送短信验证码
export function postSendMobileVerifyCode(form: {
  // 商户ID，小程序静态写死获取
  merchantAppId?: string;
  // 手机号
  mobile: string;
  //  图形验证码token，通过 获取图形验证码 接口获得
  imgToken: string;
  //  图形验证码输入内容
  imgCode: string;
}): Promise<IRequestResult> {
  form.merchantAppId = MerchantModule.merchantIdAppId;
  return request.post('/user/login/sendMobileVerifyCode', form);
}

// 手机号登录
export function postUserMobileLogin(form: {
  // 商户ID，小程序静态写死获取
  merchantAppId?: string;
  // 用户手机号码，需格式校验
  mobile: string;
  // 6位数字，测试环境固定1234
  verifyCode: string;
}): Promise<IRequestResult<loginRes>> {
  //  /user/login/userMobileLogin
  form.merchantAppId = MerchantModule.merchantIdAppId;
  return request.post('/user/login/userMobileLogin', form, { loading: true });
}

// 获取图形验证码
export function getImgVerifyCode(): Promise<
  IRequestResult<{
    imgBase64: string;
    token: string;
  }>
> {
  return request.get('/user/login/getImgVerifyCode', { hideMsg: true });
}

export interface UserCenterInfoType {
  // 卡券包
  couponCount: number;
  // 成长值
  growthValue: number;
  // 会员等级图片
  memberLevelImg: string;
  // 可用积分
  pointCount: number;
  // 商品收藏
  spuStarCount: number;
}

// 获取用户个人中心信息
export function getUserCenterInfo(): Promise<IRequestResult<UserCenterInfoType>> {
  return request.get('/user/info/collect', { hideMsg: true });
}

export interface UserOrderInfoType {
  // 待付款
  pendingPayCount: number;
  // 待发货
  pendingDeliverCount: number;
  // 发货中
  deliveringCount: string;
  // 已发货
  deliveredCount: number;
  // 已完成
  finishCount: number;
}

// 获取用户个人中心订单信息
export function getUserOrderInfo(): Promise<IRequestResult<UserOrderInfoType>> {
  return request.post('/order/order_record/count', {}, { hideMsg: true });
}

// 退出登录
export function logout(): Promise<IRequestResult<boolean>> {
  return request.get('/user/login/userLogout', { loading: true, loadingText: '' });
}

export enum LoginType {
  MINI = 'MINI',
  WECHAT = 'WECHAT',
  MEANS = 'MEANS'
}
/* 获取登录信息 */
export function getLoginConfig(): Promise<
  IRequestResult<{
    replaceUrl: string;
    loginType: LoginType;
  }>
> {
  return request.get('/user/login/getLoginConfig', { hideMsg: true, loading: true, loadingText: '' });
}

export function loginUserMeansCode(code: string): Promise<IRequestResult<loginRes>> {
  return request.post('/user/login/userMeansCodeLogin', { code }, { hideMsg: true, loading: true, loadingText: '' });
}

// 查询优惠券列表
export const getCouponList = baseQueryByPage<
  {
    current?: number;
  },
  ICouponInfo
>('/marketing/coupon/front/list');

// 卡券领取
export function receiveCoupon(data: { couponNumber: string; couponPassword: string }): Promise<IRequestResult> {
  return request.post('/marketing/coupon/front/receive', data, { loading: true, loadingText: '' });
}

export interface UserAccountLogin {
  // 微信用户信息code
  code: string;
  // 用户账号
  userAccount: string;
  // 用户密码
  userPassword: string;
  // 图片校验码token
  imgToken: string;
  // 图片校验验证码
  imgCode: string;
}
// 用户账号密码登录
export function userAccountLogin(data: UserAccountLogin): Promise<IRequestResult<loginRes>> {
  return request.post('/user/login/userAccountLogin', data, { loading: true, loadingText: '', hideMsg: true });
}
