














import { getUserOrderInfo, UserOrderInfoType } from '@/api/user';
import { UserModule } from '@/store/modules/User';
import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  get userInfo() {
    return UserModule.userInfo;
  }

  // 订单操作列表
  get options() {
    return [
      {
        name: 'pendingPayCount',
        url: 'order/list?type=1',
        icon: '//means-static.luckyop.com/images/20211112/1fk932frgf6kncrotc5g.png',
        label: '待付款'
      },
      {
        name: 'pendingDeliverCount',
        url: 'order/list?type=2',
        icon: '//means-static.luckyop.com/images/20211112/1fk93254am93fiiglao8.png',
        label: '待发货'
      },
      {
        name: 'deliveringCount',
        url: 'order/list?type=3',
        icon: '//means-static.luckyop.com/images/20211112/1fk9328k06561f5et2ho.png',
        label: '发货中'
      },
      {
        name: 'deliveredCount',
        url: 'order/list?type=4',
        icon: '//means-static.luckyop.com/images/20211112/1fk932bdn26nli9abtjg.png',
        label: '已发货'
      },
      {
        name: 'finishCount',
        url: 'order/list?type=5',
        icon: '//means-static.luckyop.com/images/20211112/1fk932dhdbu4bhvmnpho.png',
        label: '已完成'
      }
    ] as {
      [key: string]: string;
      name: keyof UserOrderInfoType;
    }[];
  }

  orderInfo = {} as UserOrderInfoType;

  async getInfo() {
    this.orderInfo = {} as UserOrderInfoType;
    if (!this.userInfo.userId) return;
    const { payload } = await getUserOrderInfo();
    this.orderInfo = payload;
  }

  activated() {
    this.getInfo();
  }
}
