import store from '@/store';
import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IUserInfo } from '@/api/user';
import { getUserInfo, setUserInfo } from '@/utils/storage';

@Module({ store, name: 'user', dynamic: true })
class UserStore extends VuexModule {
  userInfo = getUserInfo();

  @Mutation
  setUserInfo(userInfo: IUserInfo) {
    this.userInfo = userInfo;
    setUserInfo(userInfo);
  }
}

export const UserModule = getModule(UserStore);
