/*
 * @Author: Jc
 * @Date: 2022-04-25 16:22:18
 * @LastEditors: Jc
 * @LastEditTime: 2022-04-25 19:19:55
 * @FilePath: /mall-front-static/src/lib/jsskd/service.ts
 * @Description:
 *
 */

import { MerchantModule } from '@/store/modules/Merchant';
import { UserModule } from '@/store/modules/User';
import { Toast } from 'vant';

class service {
  ysf: any;
  isYsfOpen = false;
  /* 如果点击的时候没有加载完成 触发钩子 */
  openOnReady = false;
  constructor() {
    this.init();
  }

  async init() {
    await this.initScript();
    await this.initConfig();
    if (this.openOnReady) {
      Toast.clear();
      this.ysf('open');
    }
    this.isYsfOpen = true;
    // this.openOnlineService();
  }

  openOnlineService() {
    if (!this.isYsfOpen) {
      if (this.openOnReady) return;
      this.openOnReady = true;
      Toast.loading({
        message: '客服系统正在加载',
        duration: 0, // 持续展示 toast
        forbidClick: true
      });
      return;
    }

    this.ysf('open');
  }

  /* 初始用户信息 */
  initConfig() {
    const { merchantName } = MerchantModule;
    const { userId, userTel, userHeadImg } = UserModule.userInfo;
    return new Promise<void>((resolve, reject) => {
      this.ysf('config', {
        uid: userId, // 用户Id
        name: merchantName + '-商城', // 用户名称
        email: '', // 用户邮箱
        mobile: userTel, // 用户电话
        groupid: 481086538, //用户组id
        data: JSON.stringify([
          { index: 1, key: 'custId', label: '用户ID', value: userId },
          { index: 2, key: 'headImgUrl', label: '头像', value: userHeadImg },
          { index: 3, key: 'merchantName', label: '商户', value: merchantName }
        ]),
        success: () => {
          resolve();
        },
        error: () => {
          reject();
          // 错误回调
          console.log('config error');
        }
      });
    });
  }

  /* 初始化js-sdk */
  initScript() {
    return new Promise<void>((resolve, reject) => {
      if (document.getElementById('ysf-script')) {
        this.ysf = window['ysf'];
        return resolve();
      }
      window.ysf = () => {
        // eslint-disable-next-line prefer-rest-params
        (window['ysf'].a = window['ysf'].a || []).push(arguments);
      };

      const script = document.createElement('script');
      script.id = 'ysf-script';
      script.async = true;
      script.src = 'https://qiyukf.com/script/47ef54ac27fdd7d8874779b367746e7c.js?hidden=1&subdomain=1';
      document.body.appendChild(script);
      script.onload = () => {
        window['ysf']('onready', () => {
          this.ysf = window['ysf'];
          resolve();
        });
      };
      script.onerror = e => {
        reject(e);
      };
    });
  }
}

export default new service();
