























import { UserModule } from '@/store/modules/User';
import { Vue, Component } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  get userInfo() {
    return UserModule.userInfo;
  }
}
