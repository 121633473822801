























import { Vue, Component, Inject } from 'vue-property-decorator';
import MineHeader from './comm/MineHeader.vue';
import MineOrder from './comm/MineOrder.vue';
import MineSettingMenu from './comm/MineSettingMenu.vue';
import MineDataPanel from './comm/MineDataPanel.vue';
import { UserModule } from '@/store/modules/User';

import { getUserInfo, removeToken, removeUserInfo } from '@/utils/storage';
import AppVue from '@/App.vue';
@Component({
  name: '',
  components: { MineHeader, MineOrder, MineSettingMenu, MineDataPanel }
})
export default class extends Vue {
  get userInfo() {
    return UserModule.userInfo;
  }

  @Inject()
  appReload!: AppVue['appReload'];

  handleClickLogout() {
    removeToken();
    removeUserInfo();
    UserModule.setUserInfo(getUserInfo());
    this.appReload();
  }
}
